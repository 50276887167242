@use 'variables' as v; 

/** Media Queries **/
@mixin telefono {
    @media (min-width: v.$telefono) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: v.$tablet) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: v.$desktop) {
        @content;
    }
}
@mixin desktopXL {
    @media (min-width: v.$desktopxl) {
        @content;
    }
}




@mixin boton($bgColor, $colorTexto) {
    background-color: $bgColor;
    padding: 1.5rem 4rem;
    color: $colorTexto;
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: 300;
    display: inline-block;
    font-weight: 700;
    border: none;
    transition-property: background-color;
    transition-duration: .3s;
    text-align: center;

    @include tablet {
        width: auto;
    }
    &:hover {
        background-color: darken( $bgColor, 10% );
    }
}

@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}

@mixin iconoBg($url) {
    background-image: url();
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 10rem;
}

@mixin botond($color, $texto) {
    background-color: $color;
    font-size: 2rem;
    padding: 1.2rem 4rem;
    font-weight: 700;
    display: block;
    width: 100%;
    text-align: center;
    color: $texto;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border: none;
  
    &:hover {
      cursor: pointer;
      background-color: darken($color, 10);
    }
  
    @include tablet {
      width: auto;
      display: inline-block;
    }
  }