// Fuentes
$fuentePrincipal: 'Open Sans', sans-serif;
$fuenteSecundaria: 'Roboto', cursive;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;
$desktopxl: 1200px;

// Colores
$primario: #3b4862;

$negro: #000;
$blanco: #FFFFFF;
$gris: #eeefed;
$grisClaro: #f8f8f8;
$grisLine:#9c29c6