@use 'base/variables' as v;
@use 'base/mixins' as m;

.facil-avenar {
    margin-bottom: 5rem;
    margin-top: 5rem;
    padding: 3rem;
    @include m.tablet { 
        margin-top: 15rem;
        padding: 15 0rem;
    }
    @include m.tablet { 
        display: flex;
        gap: 5rem;
        align-items: center;
    }
    &:last-of-type {
        margin-bottom: 0;   
    }

    &:nth-child(2) {
        .texto{
        order: 1;
       }
       .imagen {
        order: 2;
       }
    }
    &:nth-child(4) {
        .texto{
        order: 1;
       }
       .imagen {
        order: 2;
       }
    }
    &:nth-child(6) {
        .texto{
        order: 1;
       }
       .imagen {
        order: 2;
       }
    }


    .imagen,
    .texto {
        flex: 1;;
    }

    .texto {

        h2 {
            margin: 0;
            text-align: left;
            color: v.$negro;

        }
    }

    .imagen {
        max-width: 20rem;
        
        
        
    }
}