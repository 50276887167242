@use 'base/variables' as v;

h1, h2, h3 {
    margin: 0;
    line-height: 1.2;
}
h1 {
    font-size: 4.4rem;
    font-family: v.$fuenteSecundaria;
}
h2 {
    font-size: 4rem;
    font-family: v.$fuenteSecundaria;
}
h3 {
    font-size: 3rem;
}
[class$="heading"] {
    margin: 2rem 0;
}
a {
    text-decoration: none;
    color: v.$negro;
}
p {
    color: v.$negro;
    font-size: 1.6rem;
    line-height: 1.8;
}