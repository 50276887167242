@use 'base/variables' as v;
@use 'base/mixins' as m;

.sistema {
    h2 {
        color: v.$negro;
    }
    p {
        font-size: 2.2rem;
    }
    
}