@use 'base/variables' as v;
@use 'base/mixins' as m;

.header {
 
 background-size: cover;
 background-position: center;
 padding: 3rem 0;
 position: relative;


 @include m.tablet { 
    padding: 3rem 0 20rem 0;
 }

 &__barra {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include m.tablet {
        flex-direction: row;
        justify-content: space-between;
       
    }

 }
 &__logo {
    width: 15rem;
 }

 &__contenido {
   @include m.tablet { 
    margin-top: 10rem;
    max-width: 50rem;
}
 }

 &__texto {
    color: v.$blanco;
    display: none;
    @include m.tablet { 
        display: block;
    }

 }

 &__imagen {
    display: none;
    @include m.tablet { 
        display: block;
        position: absolute;
        right: 0;
        bottom: -5rem;
        max-width: 35rem;

        border-top-left-radius: 2rem;
        border-bottom-left-radius: 5rem;
    }
    @include m.desktopXL { 
        max-width: 60rem;
    }
 }

 &__heading {
    color: v.$blanco;
    text-align: center;
    @include m.tablet { 
        text-align: left;
    }
 }
}

.navegacion {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

    @include m.tablet { 
        flex-direction: row;
        gap: 2rem;
        margin-top: 0;
    }

    &__link {
        color: v.$blanco;
        font-size: 1.8rem;
        margin-bottom: 2rem;

        &:last-of-type {
            margin-bottom: 0;
        }
        @include m.tablet { 
            margin-bottom: 0;
        }
    }
}

.header-inicio{
    background-image: linear-gradient(to right, transparentize(v.$primario, .1) 0%, transparentize(v.$primario, .1) 100%), url(../img/header_av.jpg);
 }
 .header-avenar{
    background-image: linear-gradient(to right, transparentize(v.$primario, .05) 0%, transparentize(v.$primario, .1) 100%), url(/build/img/bild_2_final.webp);
    background-position: right, 20rem ;
    background-repeat: no-repeat;
    background-size: cover;
 }
