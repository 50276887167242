@use 'variables' as v; 
@use 'base/mixins' as m;
html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
  
body {
    font-family: v.$fuentePrincipal;
    font-size: 1.6rem;
    background-color: darken(v.$grisClaro, 3%);
}
.contenedor {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
}
img {
    max-width: 100%;
    display: block;
}
ul {
    padding:0;
    list-style: none;
    margin: 0;
}

h1,h2,h3 {
    margin: 0 0 2rem 0;
}
h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3.6rem;
    display: inline-block;
    margin-top: 5rem;
    color: v.$blanco;
    @include m.tablet { 
        margin-top: 0;
    }

    &::after {
        content: '';
        background-color: v.$grisLine;
        height: 1rem;
        width: calc(100% + 1rem);
        display: block;
        margin: -1.4rem 0 0 0;

    }

}
h3 {
font-size: 2.8rem;
text-align: center;
text-transform: uppercase;
}

.seccion {
    margin-top: 5rem;
    padding: 3rem;
    @include m.tablet { 
        margin-top: 15rem;
        padding: 15 0rem;
    }
}