@use 'base/variables' as v;
@use 'base/mixins' as m;

.razones {
    background-color: v.$primario;
    p {
        color: v.$blanco;
    }
}

.descripcion {
    color: v.$blanco;
    font-size: 2rem;
    
}

.nuestras-razones {
    display: flex;
    flex-direction: column;
    @include m.tablet { 
        flex-direction: row;
        max-width: 80rem;
        margin-inline: auto;
        margin-block-start: 10rem;
    }
    .razon {
       
        flex-grow: 1;
        background-color: v.$blanco;
        padding: 16rem 4rem 4rem 4rem;
        border-bottom: 1px solid #e1e1e1;
        @include m.tablet { 
            border: none;

            &:hover {
                transform: scale(1.1);
                transition-property: transform;
                transition-duration: 300ms;

            }
        }
        .comentario {
            font-size: 1.5rem;
            line-height: 1.5;
            text-align: left;
        }

        .listado {
            li {
                text-align: center;
                margin-bottom: 2rem;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        

        &.rendimiento {
            
            background-image: url(../img/flama_banner.svg);
            background-repeat: no-repeat;
            background-position: 50% 2rem;
            background-size: 10rem;
        }
        &.escalabilidad {
            background-image: url(../img/cuadrado_banner.svg);
            background-repeat: no-repeat;
            background-position: 50% 2rem;
            background-size: 10rem;

        }
        &.configuracion {
            background-color: v.$grisLine;
            order: -1;
            background-image: url(/build/img/setting_banner.svg);
            background-repeat: no-repeat;
            background-position: 50% 2rem;
            background-size: 10rem;
            @include m.tablet { 
                order: unset;
                margin-top: -5rem;
                margin-bottom: -5rem;
                border-radius: 2rem;
                background-position: 50% 7rem;
                padding-top: calc(16rem + 5rem);
                z-index: 100;
            }
        }
        p {
            color: v.$negro;
        }
    }
}

