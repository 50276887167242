@use 'base/variables' as v;
@use 'base/mixins' as m;

.crea-grid {
    h2 {
        color: v.$negro;
    }
@include m.tablet { 
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

   
}
       
}